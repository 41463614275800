export const NATIONAL_COLLECTOR = 'national_collector';
export const COLLECTOR_URL1 = 'collector_url1';
export const COLLECTOR_URL2 = 'collector_url2';
export const COLLECTOR_URL3 = 'collector_url3';
export const COLLECTOR_URL4 = 'collector_url4';
export const COLLECTOR_URL5 = 'collector_url5';
export const INITIATIVES_EXISTENCE = 'initiatives_existence';
// Automatic
export const DIRECTORY = 'directory';
export const COLLECTOR = 'collector';
export const STANDARD = 'standard';
export const URL = 'url';
export const OPEN_ACCESS = 'open_access';
